import * as React from "react"
// import lottie from "lottie-web"
import lottie from "lottie-web/build/player/lottie_light"
import { resourcesPageData } from "components/Data/ResourcesPageData"
import styled from "styled-components"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import View from "components/View"
import device from "utils/media"
import PageHeader from "components/PageHeader"
import Leadins from "components/Leadins"
import ApiAnimation from "../../content/assets/animations/user-data-2.json"
import IconPdf from "../../content/assets/icon-pdf.svg"

const SectionWrapper = styled.div`
  padding: ${props => (props.zeroPadding ? "0" : "60px 0")};
  @media ${device.lg} {
    padding: ${props => (props.zeroPadding ? "0" : "70px 0")};
  }
  background-color: ${props => props.bgColour};
  color: ${props => props.textColour};

  h3,
  h4 {
    text-transform: none;
    font-weight: 600;
    font-family: ${props => props.theme.font.body};
  }

  .lead {
    @media ${device.lg} {
      font-size: 20px;
    }
    @media ${device.xl} {
      font-size: 24px;
    }    
  }
`

const ApiSection = styled.div`
  padding-bottom: 20px;
`

const Downloads = styled.div`
  p {
    margin-bottom: 12px;
  }

  .download-pdf {
    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
`

const ResourcesPage = () => {
  const page = resourcesPageData

  const verificationSample = `{ 
    "user": "sample", 
    "password": "abc123", 
    "fuzzy_match": "1",
    "country_code": "AU", 
    "first_name": "Scott",
    "last_name": "WINR",
    "address1": "100 Walker Street",
    "suburb": "North Sydeny",
    "state": "NSW",
    "postcode": "2060"
  }`

  const verificationResponse = `{ 
    "first_initial": "match",
    "first_name": "match",
    "last_name": "no_match",
    "address1": "match",
    "suburb": "partial",
    "state": "match",
    "postcode": "match",
    "partial_match_scores": {
        "suburb": 95
    },
  }`
  
  const PrettyPrintCode = ({ data }) => {
    // (destructured) data could be a prop for example
    return (
      <div className="code-highlight">
        <pre>
          <code>{data}</code>
        </pre>
      </div>
    )
  }  

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#api-animation"),
      animationData: ApiAnimation,
    })
  }, [])

  return (
    <Layout pageName="resources">
      <SeoComponent title="Resources" description="" />
      {page && page.page_header && (
        <PageHeader fields={page.page_header} pageName="resources" />
      )}
      <SectionWrapper as={View} bgColour={"#fafafa"} textColour={"#000000"}>
        <div className="container x-large">
          <div className="row">
            <div className="col md-10">
              <h2>API Integration</h2>
              <p className="lead" style={{ color: "black" }}>
                The WINR Data API is a standard REST API, using JSON-encoded
                request bodies and responses and standard HTTP response codes.
              </p>
            </div>
            <div className="col md-10 lg-7">
              <ApiSection>
                <h3>Consumer Data Match</h3>
                <p>
                  This API method will attempt to match provided consumer data elements with the consumers in the WINR Data lake.  The consumer data elements could include email, phone, name or address components. An example API query could be:
                </p>
                <PrettyPrintCode data={verificationSample} />
                <p>
                  The API response will indicate which consumer data elements matched any records in the WINR Data Lake.  If fuzzy-matching is specified, it will provide partial match scores (based on Levenshtein distance ratios).  An example response could be:
                </p>
                <PrettyPrintCode data={verificationResponse} />                
              </ApiSection>
            </div>
            <div className="col lg-5">
              <div id="api-animation"></div>
            </div>
          </div>
        </div>
      </SectionWrapper>

      <Leadins pageName="resources" />
    </Layout>
  )
}

export default ResourcesPage
