import React from "react"
import { InView } from "react-intersection-observer"
import posed from "react-pose"
import styled from "styled-components"

const ViewAnimation = {
  initialPose: "hide",
  visible: {
    opacity: 1,
    y: "0"
  },
  hidden: {
    opacity: 0,
    y: "30px"
  }
}

const ViewStyled = styled(posed.div(ViewAnimation))`
  transition: ${props => props.theme.transition.slow};
`
const threshold = 0.3

const View = props => (
  <InView threshold={threshold} triggerOnce={true} {...props}>
    {({ inView, ref }) => (
      <ViewStyled ref={ref} {...props} pose={inView ? "visible" : "hidden"}>
        {props.children}
      </ViewStyled>
    )}
  </InView>
)

export default View
