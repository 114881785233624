const resourcesPageData = {
  page_header: {
    heading: `Resources`,
    style: "jumbo",
    subheading: ``,
  },
}

export {
  resourcesPageData
}